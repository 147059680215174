.sageGalleryIn {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
}

.sageGalleryIn iframe{
    width: 30vmax;
    height: 15vmax;
}
.container.gallery_grid{
    margin-top: 0.5rem;
}
._Bqct9{
    margin-left: 0 !important;
}