@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

html {
  scroll-behavior: smooth;
}

:root {
  --head: #242424;
  --para: #4c4c4c;
}

body {
  background: #efefef;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  /* width: 100%; */
  display: block;
}

.invertimage {
  filter: invert(1);
}

.container {
  margin: 0 auto;
  margin-bottom: 20px;
}

.sagebuttons {
  max-width: 250px;
  padding: 12px 30px;
  background: transparent;
  border: 1px solid #242424;
  border-radius: 37px;
  font-size: 15px;
  color: #242424;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: all .5s;
}

.sagebuttons:hover {
  box-shadow: inset 1px 1px 5px 1px #242424;

}

.sagefooterInContact a {
  color: var(--para);
}

nav.sageNavbar.sageNavbarDesktop {
  display: none;
}

ul.sageNavigation {
  display: flex;
  gap: 35px;
  transition: 0.5s all;
  align-items: center;
}

ul.sageNavigation li {
  list-style: none;
}

ul.sageNavigation li a {
  font-family: "Unbounded";
  transition: cubic-bezier(0.19, 1, 0.22, 1) .5s;
  font-weight: 500;
}

ul.sageNavigation li a:hover {
  color: var(--para);
}

@media screen and (max-width: 768px) {
  .sage-texture__difference {
    background-image: url("./components/img/backbodymob.jpg");
  }
}

@media screen and (min-width: 768px) {
  .container {
    width: 90%;
  }

  nav.sageNavbar.sageNavbarMobile {
    display: none;
  }

  nav.sageNavbar.sageNavbarDesktop {
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    width: 980px;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    width: 1100px;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: var(--head);
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}



.sagepassesconinImage {
  position: relative;
}

.sagepassesconinImage img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.sagepassesconinImage:hover img {
  transform: scale(1.05);
}

.sagepassesconincon p {
  margin-bottom: 10px;
}

.sagepassesconincon p:last-child {
  margin-bottom: 0;
}

.instagram-icon {
  background-color: rgba(0, 0, 0, 0.7);
  /* Add contrast to the icon */
}

.sagepassesconinImage:hover .instagram-icon {
  opacity: 1;
}