h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--head);
}

.App {
  overflow: hidden;
}

.sageheader {
  padding-bottom: 60px;
}

.sageheadercont {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

.sageheaderconthead {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.sageheaderconthead h1 {
  text-align: center;
  font-size: 55px;
  font-family: "Unbounded", sans-serif;
}

.sageheaderconthead p {
  font-family: "Unbounded", sans-serif;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  line-height: 35px;
}

.sageheadervideo {
  display: flex;
  justify-content: center;
}

.sageheadervideo video {
  /* height: calc(70vh - 79px); */
  object-fit: cover;
}

.sageheadercontBtn .scroll img {
  width: 20px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  animation-name: scroll;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  transition: all 1s;
}

.sageheadercontBtn .scroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  padding: 30px 0;
}

.sageheadercontBtn .scroll p {
  font-size: 15px;
}

.sageheadercontBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@keyframes scroll {
  0% {
    bottom: 10px;
  }

  25% {
    bottom: 5px;
  }

  50% {
    bottom: 0px;
  }

  75% {
    bottom: 5px;
  }

  100% {
    bottom: 10px;
  }
}

.sageeventsaboutus {
  padding-top: 30px;
}

.sageeventsaboutusCon h2 {
  font-size: 55px;
}

p {
  color: var(--para);
  font-size: 15px;
  font-weight: 500;
}
.overlay {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.694);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  animation: ins 0.5s;
}
@keyframes ins {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.overlay .close {
  cursor: pointer;
  position: absolute;
  z-index: 4;
  top: 1vmax;
  right: 3vmax;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 25px;
  color: yellow;
}
.overlay img {
  position: absolute;
  height: 90%;
}
@media screen and (max-width: 1200px) {
  .overlay img {
    height: unset;
    width: 100%;
  }
  
}


.sageeventsaboutusfirst {
  display: grid;
  align-items: center;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
}

.sageeventsaboutusSecond {
  padding-top: 20px;
}

.sagepasses h2 {
  color: #f9a829;
}

.sagepassesbg .sagebuttons {
  color: #fff;
}

.sagepassesintrobutton {
  max-width: 250px;
  padding: 12px 30px;
  background: #ffa42c;
  border-radius: 37px;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: all .5s;
  border: 2px solid transparent;
}

.sagepassesintrobutton:hover {
  background: transparent;
  border: 2px solid #ffa42c;
}

.sagepasses .sagepassesintro h3 {
  text-align: center;
  color: #fff;
}

.sagepasseshead h2,
.sageourteamhead h2 {
  font-size: 75px;
  font-family: "Unbounded", sans-serif;
  text-align: center;
}

.sagepassescon {
  display: grid;
  gap: 20px;
  padding: 20px 0;
  grid-template-columns: 1fr 1fr;
}

.sagepassesconin h3 {
  font-family: "Unbounded", sans-serif;
  font-size: 25px;
  font-weight: 400;
}

.sagepassesconin .sagepassesconincon {
  display: grid;
  gap: 10px;
  padding-top: 20px;
}

.sagepassesconinImage {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.sagepassesconin {
  display: grid;
  align-items: center;
  background: #fff;
}

.sagepasses {
  padding: 50px 0;
}

.sagepassesintro {
  display: flex;
  gap: 15px;
  align-items: center;
  flex-direction: column;
  padding: 20px 30px;
}

.sagepassesintro h3 {
  font-family: "Unbounded", sans-serif;
  font-size: 35px;
  font-weight: 400;
}

.sagepassesintro>p {
  text-align: center;
  color: #e9e9e9;
}

.sageourteammember {
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  color: white;
}

.sageourteammember img {
  cursor: pointer;
  transition: all 0.5s;
}

.sageourteammember img:hover {
  transform: scale(1.1);
}

.sageourteammembers {
  color: white;
  display: flex;
  gap: 30px;
  padding-top: 30px;
  justify-content: center;
}

.sageourteammembers p {
  padding-top: 10px;
  width: 100%;
  text-align: center;
}

.sageourteam {
  margin-top: 70px;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  gap: 20px;
}

.top {
  overflow: hidden;
}

.img-cnt {
  width: 160%;
  display: flex;
  align-items: center;
  gap: 30px;
}


.image_wrapper {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 100px;
  overflow: hidden;
  margin: 0px 30px;
  background-color: white;
}

.outlined_text {
  color: #000;
  text-shadow:
    -1px -1px 0 #fff,
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
    1px 1px 0 #fff
}

@supports((text-stroke: 2px black) or (-webkit-text-stroke: 2px black)) {
  .outlined_text {
    color: transparent;
    -webkit-text-stroke: 2px #fff;
    text-stroke: 2px #fff;
    text-shadow: none;
  }
}

@media screen and (max-width: 768px) {
  .img-cnt {
    width: 500%;
  }

  .sageheadercont {
    min-height: calc(100vh - 62px);
  }
}

@media screen and (max-width: 1023px) {
  p {
    font-size: 12px;
  }
  .sageourteammembers {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .sagepassesconin .sagepassesconincon {
    height: 100%;
  }
  .sagepassescon {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 1024px) {
  .sageheadercont {
    position: static;
    transform: translateX(0);
  }

  .sageeventsaboutus {
    padding-top: 20px;
  }

  .sageheaderconthead h1 {
    font-size: 60px;
  }

  .sageeventsaboutusCon h2 {
    font-size: 35px;
  }

  .sagepasseshead h2,
  .sageourteamhead h2 {
    font-size: 50px;
  }

  .sagepassesintro h3 {
    font-size: 25px;
  }

  
}

@media screen and (max-width: 768px) {
  .sageheaderconthead {
    padding: 20px;
  }
  .sagepassesconin h3 {
    font-size: 18px;
  }
  .sageheadercontBtn {
    flex-direction: column;
    gap: 15px;
  }

  .sageheadercont {
    min-height: 100%;
    gap: 30px;
  }

  .sageheaderconthead p {
    line-height: normal;
  }

  .sageheadervideo video {
    filter: brightness(1);
    width: 100%;
  }

  .sageheadercontBtn .scroll {
    display: none;
  }

  .sageheaderconthead h1 {
    font-size: 25px;
  }

  .sageheaderconthead p {
    width: 100%;
    padding: 0 20px;
  }

  .sageeventsaboutusfirst {
    grid-template-columns: 1fr;
  }

  .sageeventsaboutus,
  .sagepasses {
    padding: 20px;
  }

  .sagepasseshead h2,
  .sageourteamhead h2 {
    font-size: 25px;
  }

  .sagepassesintro h3 {
    font-size: 16px;
  }

  .sagepassesintro {
    padding: 20px 0;
  }

  .sagepasses,
  .sageourteam {
    margin-top: 30px;
  }
}