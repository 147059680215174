.link {
    width: 150px;
    height: 150px;
    display: inline-block;
    font: 300 1.55rem/1.4 "Josefin Sans";
    text-transform: uppercase;
    letter-spacing: 0.1175em;
    word-spacing: 0.3em;
    text-decoration: none;
    background: #f7f6f6;
    border-radius: 50%;
    padding: 10px;
}

.link__svg {
    width: 100%;
    height: auto;
    transform-box: fill-box;
    fill: #2b3338;
    stroke: #2b3338;
    stroke-width: 0.05em;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.link__cloud {
    transform-origin: 50% 50%;
    -webkit-animation: rotate normal infinite 60s linear;
    animation: rotate normal infinite 60s linear;
    fill: rgba(255, 255, 255, 0.15);
}

.link__face,
.link__arrow {
    transform-origin: 50% 50%;
    transition: transform 0.15s cubic-bezier(0.32, 0, 0.67, 0);
}

.link:hover .link__face,
.link:hover .link__arrow {
    transform: scale(1.1);
    transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
}

.link__arrow {
    stroke-width: 0.075em;
}

.link__text {
    -webkit-animation: rotateReverse normal infinite 20s linear;
    animation: rotateReverse normal infinite 20s linear;
    transform-origin: 50% 50%;
}

.link:hover .link__text {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.link--alt {
    font-size: 1.15rem;
    letter-spacing: 0;
    word-spacing: 0;
}

@-webkit-keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotateReverse {
    to {
        transform: rotate(-360deg);
    }
}

@keyframes rotateReverse {
    to {
        transform: rotate(-360deg);
    }
}
@media screen and (max-width:767px) {
    .link {
        width: 100px;
        height: 100px;
    }
}

/* .sageAnime-btn */



.sageAnime-btn{
    background: none;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    line-height: 24px;
    width: 400;
    transition: 0.3s;
    cursor: pointer;
    position: relative;
    outline: none;
}
.sageAnime-cutter::before
{
    content: "";
    position: absolute;
    z-index: -1;
    transition: 0.5s;
}
.sageAnime-cutter{
    border: 2px solid #efefef;
    border-radius: 5px;
    box-shadow: 3px 3px 0 #e6e6e7;
    font-size: 1.125rem;
    font-weight: 700;
    overflow: hidden;
    color: #fff;
}
.sageAnime-cutter::before{
    top: 100%;
    left: 0;
    width: 150%;
    aspect-ratio: 1;
    background: #fff;
    transform: translate(50%,0%) rotateZ(-45deg);
}
.sageAnime-cutter:hover::before{
    transform: translate(-20%,-60%) rotateZ(-45deg);
}
.sageAnime-cutter:hover{
    box-shadow: 0 0 0;
    color: #ffa42c;
}
