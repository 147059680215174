.social-icons {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgba(21, 21, 21, 0.5);
    padding: 10px;
    transition: background-color 0.3s ease;
}

.social-icon {
    margin: 0 10px;
    font-size: 20px;
    color: white;
    transition: color 0.3s ease; 
}

.social-icon:hover {
    color: #333;
}

.social-icons:hover {
    background: rgba(21, 21, 21, 0.8);
    box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.5);
}