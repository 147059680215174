.sagefooterIn {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
}

.sagefooterIn img {
  width: 30px;
}
.sagefooterIn .sagefooterInContact img {
  width: 15px;
}

.sagefooterIn>div {
  display: flex;
  align-items: center;
  gap: 15px;
}

.sagefooterIn>div.sagefooterInContact {
  flex-direction: column;
  align-items: baseline;
  gap: 10px;
}

.sagefooterInContact .contact {
  display: flex;
  align-items: center;
  gap: 5px;
}

.sagefootershoutout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  padding-top: 20px;
}

.sagefootershoutout a {
  color: var(--para);
}

/* Navbar */
.sageNavMenu span {
  font-size: 1.5vmax;
  font-family: "Unbounded", sans-serif;
}

.sageNavbar,
.sagePanchCnt,
.sageNavMenu {
  display: flex;
  align-items: center;
  gap: 0.5vmax;
}

.sageNavbar {
  width: 100%;
  /* position: fixed; */
  color: white;
  justify-content: space-between;
  padding: 1vmax 3vmax;
  background-color: black;
}

.SageEuphoriaLogo {
  width: 70px;
}
a.sageNavLogo {
  padding: 10px;
}
.sagePanchSvg {
  width: 25px;
  border-radius: 100px;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 1s;
  mix-blend-mode: hard-light;
}

.sagePanchCnt li:nth-child(2) .sagePanchSvg,
.sagePanchCnt li:nth-child(4) .sagePanchSvg {
  width: 35px;
}

.sagePanchCnt li:nth-child(3) .sagePanchSvg {
  width: 45px;
  mix-blend-mode: normal;
}

.sagePanchCnt {
  list-style: none;
  cursor: pointer;
}

.sagePanchSvg:hover {
  transform: scale(1.1);
  mix-blend-mode: normal;
}

.sagePanchCnt li:nth-child(1) .sagePanchSvg:hover {
  box-shadow: 0px 0px 15px 0px white;
}

.sagePanchCnt li:nth-child(2) .sagePanchSvg:hover {
  box-shadow: 0px 0px 15px 0px red;
}

.sagePanchCnt li:nth-child(3) .sagePanchSvg:hover {
  box-shadow: 0px 0px 15px 0px green;
}

.sagePanchCnt li:nth-child(4) .sagePanchSvg:hover {
  box-shadow: 0px 0px 15px 0px rgb(0, 183, 255);
}

.sagePanchCnt li:nth-child(5) .sagePanchSvg:hover {
  box-shadow: 0px 0px 15px 0px blue;
}

.hamburger {
  cursor: pointer;
}

.hamburger input {
  display: none;
}

.hamburger svg {
  height: 3em;
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
  fill: none;
  stroke: white;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
  stroke-dasharray: 12 63;
}

.hamburger input:checked+svg {
  transform: rotate(-45deg);
}

.hamburger input:checked+svg .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
}

.hamburger input:checked~.sageSideNav {
  bottom: 0;
  border-radius: 0px;
  background-color: rgb(14, 14, 14);
}

.sageSideNav {
  width: 100vw;
  height: 100vh;
  position: fixed;
  bottom: -100%;
  left: 50%;
  transform: translate(-50%);
  border-radius: 150% 150% 0 0;
  z-index: 99;
  transition: ease-in-out 1s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 4vmax;
  gap: 5px;
}

.sageSideNav li,
.sageSideNav li a {
  list-style: none;
  letter-spacing: -3px;
  font-family: "Unbounded";
  transition: cubic-bezier(0.19, 1, 0.22, 1) .5s;
  font-weight: 500;
}

.sageSideNav li:hover {
  letter-spacing: 0;
  list-style: outside;
}
.sageNavbar .sagefooterInSocial{
  width: 30px;
} 
@media screen and (max-width: 768px) {
  .sagefooterIn {
    flex-direction: column;
  }

  .sagefootershoutout {
    padding-bottom: 30px;
    flex-direction: column;
    gap: 10px;
  }

  .sagePanchCnt {
    display: none;
    position: fixed;
    z-index: 9999;
    background-color: black;
    width: 100%;
    justify-content: center;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    padding: 10px;
  }

  .hamburger input:checked~.sageSideNav {
    bottom: 0;
  }
}